<template>
  <v-container fluid>
    <v-app-bar
      class="leaveBar mb-2"
      dense
      style="background-color: #f0f3f4"
      :style="{ border: '0' }"
    >
      <div class="text-lg-h5" style="font-weight: 300">Budget List</div>
      <v-spacer></v-spacer>
      <v-breadcrumbs
        customDivider
        divider="/"
        large
        :items="path"
      ></v-breadcrumbs>
    </v-app-bar>
    <!--BEGIN summary stats cards-->
    <!--BEGIN summary stats cards-->
    <v-form class="statistic-cards my-4">
      <template>
        <v-container fluid>
          <v-row>
            <v-col class="d-flex py-0" cols="6" sm="3">
              <v-select
                :items="donors"
                label="Select Donor"
                item-value="code"
                item-text="displayName"
                v-model="formData.globalDimension1Code"
                :disabled="donors === null || undefined"
                style="height: 56px"
                outlined
              ></v-select>
            </v-col>

            <v-col class="d-flex py-0" cols="6" sm="3">
              <v-select
                :items="ledgers"
                label="Select GL Number"
                item-value="number"
                item-text="number"
                v-model="formData.no"
                :disabled="ledgers === null || undefined"
                style="height: 56px"
                outlined
              ></v-select>
            </v-col>

            <v-col class="d-flex py-0" cols="6" sm="3">
              <v-select
                :items="activities"
                label="Activities"
                item-value="code"
                item-text="displayName"
                v-model="formData.globalDimension2Code"
                :disabled="activities === null || undefined"
                style="height: 56px"
                outlined
              ></v-select>
            </v-col>

            <v-col class="d-flex py-0" cols="6" sm="3">

              <v-btn
                color="success"
                class="mr-4"
                @click="fetchBudgets"
                style="height: 56px"
                :disabled="activeBudgetName === undefined"
              >
                Fetch Budgets
              </v-btn>
            </v-col>
          </v-row>
        </v-container>
      </template>
    </v-form>
    <!--END summary stats-->
    <v-row>
      <v-col cols="12">
        <v-card tile class="mt-3" elevation="0">
          <v-card flat>
            <template v-if="budgets !== undefined || null">
              <!--BEGIN top bar-->
              <v-card-title class="top-bar mb-2 ">
                <div class="d-flex justify-space-between" style="width: 100%">
                  <h3 class="primary--text text-lg-h5 mr-2">Budgets</h3>
                  <v-spacer></v-spacer>
                  <v-text-field
                    v-model="search"
                    append-icon="mdi-magnify"
                    label="Search"
                    single-line
                    hide-details
                    dense
                  ></v-text-field>
                </div>
              </v-card-title>
              <!--END top bar-->
              <v-card-text>
                <v-data-table
                  :headers="headers"
                  :items="budgets"
                  :search="search"
                  :sort-by.sync="sortBy"
                  :sort-desc.sync="sortDesc"
                  :items-per-page="5"
                  fixed-header
                  class="table-font table-striped"
                >
                  <!--BEGIN date slot-->
                  <!--eslint-disable-next-line -->
                  <template v-slot:item.postingDate="{ item }">
                    <span class="table-font">{{
                      item.postingDate | formatDate
                    }}</span>
                  </template>
                  <!--END date slot-->
                  <!--BEGIN description slot-->
                  <!--eslint-disable-next-line -->
                  <template v-slot:item.travelDate="{ item }">
                    <span class="table-font">{{
                      item.travelDate | formatDate
                    }}</span>
                  </template>
                  <!--END date slot-->
                  <!--BEGIN amount slot-->
                  <!--eslint-disable-next-line -->
                  <template v-slot:item.distanceTraveled="{ item }">
                    <span class="table-font">
                      {{ item.distanceTraveled | number }}KM</span
                    >
                  </template>
                  <template v-slot:item.estimateAmount="{ item }">
                    <span class="table-font">
                      {{ item.estimateAmount | currencyFormat }}</span
                    >
                  </template>
                  <template v-slot:item.amountCharged="{ item }">
                    <span class="table-font">
                      {{ item.amountCharged | currencyFormat }}</span
                    >
                  </template>
                  <!--END amount slot-->
                  <!--BEGIN status slot-->
                  <!--eslint-disable-next-line -->
                  <template v-slot:item.status="{ item }">
                    <v-icon
                      small
                      left
                      :color="
                        item.status === 'Approved' && item.status === 'Open'
                          ? getColor('Review').color
                          : getColor(item.status).color
                      "
                      >mdi-radiobox-marked</v-icon
                    >
                    <span small text-color="white">
                      <span>{{ item.status | capitalize }}</span>
                    </span>
                  </template>
                  <!--END status slot-->
                  <!---BEGIN actions slot-->
                  <!--eslint-disable-next-line -->
                  <template v-slot:item.action="{ item }">
                    <div class="d-flex justify-start">
                      <v-tooltip bottom>
                        <template v-slot:activator="{ on, attrs }">
                          <v-chip
                            color="success"
                            v-bind="attrs"
                            v-on="on"
                            label
                            small
                            class="mx-1 text--white text-capitalize"
                          >
                            <v-icon small left color="white">mdi-eye</v-icon>
                            view
                          </v-chip>
                        </template>
                        <span>view Details</span>
                      </v-tooltip>
                    </div>
                  </template>
                  <!--END actions slot-->
                </v-data-table>
              </v-card-text>
              <!-- END the table of requests-->
            </template>
            <template v-else>
              <v-card-title class="top-bar mb-2">
                <div class="text-center" style="width: 100%">
                  <h3 class="primary--text text-lg-h5 mr-2 text-center">
                    No budgets Available. Filter to fetch budgets.
                  </h3>
                </div>
              </v-card-title>
            </template>
          </v-card>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import { AuthService } from "@kinetics254/cassandra-base/modules/auth";
import helper from "@kinetics254/cassandra-base/utilities/helpers/helper";

export default {
  name: "BudgetList",
  data: function () {
    return {
      sortBy: "no",
      sortDesc: true,
      loaded: false,
      search: "",
      filterDate: [],
      filterDatePicker: "",
      tab: null,
      status: "All",
      headers: [
        { text: "#No", align: "center", sortable: true, value: "no" },
        { text: "Name", align: "center", value: "name" },
        { text: "Amount", align: "center", value: "amount" },
        { text: "Amount in Local Currency", align: "center", value: "amountLCY" },
        { text: "Balance", align: "", value: "balanceLCY" },
        { text: "Budgeted Amount", align: "", value: "budgetedAmount" },
        { text: "Budgeted Amount in Local Currency", align: "", value: "budgetedAmountLCY" },
        {
          text: "Commitments Encumbrance",
          align: "",
          value: "commitmentsEncumbrance",
        },
        {
          text: "Commitments Encumbrance in Local Currency",
          align: "",
          value: "commitmentsEncumbranceLCY",
        },
        {
          text: "globalDimension1Code",
          align: "center",
          value: "globalDimension1Code",
        },
        {
          text: "globalDimension2Code",
          align: "center",
          value: "globalDimension2Code",
        },
      ],
      dialog: false,
      path: this.$router.currentRoute.meta.breadcrumb,
      formData: {
        budgetName: "",
        no: "",
        globalDimension1Code: "",
        globalDimension2Code: "",
      },
    };
  },
  beforeRouteEnter(to, from, next) {
    next((v) => {
      v.$store.dispatch("budget/getGeneralLedgers");
      v.$store.dispatch("budget/getDonors");
      v.$store.dispatch("budget/getActivities");
      v.$store.dispatch("budget/getBudgetName");
    });
  },
  mounted() {
    Event.$on("re-sync", () => {
      this.$store.dispatch("budget/getBudgetList", this.user.employee);
    });
  },

  computed: {
    budgets() {
      return this.$store.getters["budget/budgetGetters"]("budgetList")
        .budgetsVsDimensionEntries;
    },

    ledgers() {
      return this.$store.getters["budget/budgetGetters"]("ledgers");
    },

    donors() {
      return this.$store.getters["budget/budgetGetters"]("donors");
    },

    activities() {
      return this.$store.getters["budget/budgetGetters"]("donors");
    },

    budgetName() {
      return this.$store.getters["budget/budgetGetters"]("budgetName");
    },

    activeBudgetName() {
      const budgetName = this.budgetName || [];
      return budgetName.filter((budget) => budget.activeBudget === true)[0];
    },

    user() {
      return AuthService.user;
    },

    dateRangeText() {
      return this.filterDate.join(" To ");
    },
  },
  methods: {
    getColor(val) {
      return helper.getColor(val);
    },

    closeDialog: function (val) {
      this.dialog = val;
    },

    fetchBudgets: function () {
      this.formData.budgetName =
        this.activeBudgetName === undefined
          ? ""
          : this.activeBudgetName.budgetName;
      this.$store.dispatch("budget/getBudgetList", this.formData);
      this.clearFormData();
    },
    clearFormData: function () {
      this.formData.no = "";
      this.formData.budgetName = "";
      this.formData.globalDimension1Code = "";
      this.formData.globalDimension2Code = "";
    },
  },
  watch: {
    filterDate() {
      if (this.filterDate.length === 1) {
        this.startDate = new Date(this.filterDate[0]);
        this.endDate = new Date(this.filterDate[0]);
      }
      if (this.filterDate.length === 2) {
        this.startDate = new Date(this.filterDate[0]);
        this.endDate = new Date(this.filterDate[1]);
      }
    },
  },
};
</script>
<style scoped>
@import url("../styles/style.css");

* {
  font-family: Poppins, sans-serif !important;
}

.top-bar {
  background-color: #e8eaf6 !important;
  cursor: pointer;
  border-bottom: 1px solid #e8eaf6;
}

.request-header {
  font-family: Poppins, sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 175.6%;
  text-align: justify;
  color: var(--v-primary-base);
  text-transform: uppercase;
}

.statistic-cards-hover {
  box-shadow: 0 40px 60px -20px rgba(12, 5, 62, 0.15);
  z-index: 100;
  cursor: pointer;
  font-size: 14px;
  background-color: #e8eaf6;
}
</style>
